

export class Loader {

    constructor(elm = document.getElementById('loader')) {
        this.loader = elm;
        this.layoutContent = document.querySelector('.layout__content') ? document.querySelector('.layout__content') : document.body;
    }

    show () {
        if (this.loader && this.layoutContent) {

            this.loader.classList.remove('loader--hide');
            this.layoutContent.classList.add('loader--visible');

            setTimeout(() => {

                this.loader.classList.add('fondu-in');

                setTimeout(() => {
                    this.loader.classList.remove('fondu-out');
                }, 180);

            }, 180);

        }
    }

    hide () {
        if (this.loader && this.layoutContent) {

            this.loader.classList.remove('fondu-in');

            setTimeout(() => {

                this.loader.classList.add('fondu-out');
                this.layoutContent.classList.remove('loader--visible');

                setTimeout(() => {
                    this.loader.classList.add('loader--hide');
                }, 180);

            }, 80);
        }
    }


    // const loader = document.querySelector(selector)
    // window.addEventListener('load', () => {
    //     loader.style.opacity = "0"
    //     loader.style.zIndex = "-50"
    //     loader.style.transition = "all .7s ease"
    //     setTimeout(() => {
    //         loader.remove()
    //     },'2000')
    // });

}
